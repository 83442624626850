<script lang="ts" setup>
const { t } = useT();
</script>
<template>
	<div class="box-loading text-neutral-50">
		<AText class="title" :size="16" :modifiers="['bold']" as="h3">
			{{ t("Search results:") }}
		</AText>

		<div class="skeleton-games">
			<AAnimationSkeleton v-for="(item, index) in 3" :key="index" class="skeleton-wrapper">
				<ASkeleton width="100%" height="100%" />
			</AAnimationSkeleton>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.box-loading {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.title {
	display: flex;
	align-items: center;
	min-height: 24px;
}

.skeleton-games {
	display: grid;
	grid-template-columns: repeat(3, 94px);
	grid-gap: 8px;
}

.skeleton-wrapper {
	height: 106px;
}
</style>
